import React from "react";

const GithubLogo = () => {
  return (
    <>
      <svg
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        width="48px"
        height="48px"
      >
        <path d="M32,6C17.641,6,6,17.641,6,32c0,12.277,8.512,22.56,19.955,25.286c-0.592-0.141-1.179-0.299-1.755-0.479V50.85 c0,0-0.975,0.325-2.275,0.325c-3.637,0-5.148-3.245-5.525-4.875c-0.229-0.993-0.827-1.934-1.469-2.509 c-0.767-0.684-1.126-0.686-1.131-0.92c-0.01-0.491,0.658-0.471,0.975-0.471c1.625,0,2.857,1.729,3.429,2.623 c1.417,2.207,2.938,2.577,3.721,2.577c0.975,0,1.817-0.146,2.397-0.426c0.268-1.888,1.108-3.57,2.478-4.774 c-6.097-1.219-10.4-4.716-10.4-10.4c0-2.928,1.175-5.619,3.133-7.792C19.333,23.641,19,22.494,19,20.625 c0-1.235,0.086-2.751,0.65-4.225c0,0,3.708,0.026,7.205,3.338C28.469,19.268,30.196,19,32,19s3.531,0.268,5.145,0.738 c3.497-3.312,7.205-3.338,7.205-3.338c0.567,1.474,0.65,2.99,0.65,4.225c0,2.015-0.268,3.19-0.432,3.697 C46.466,26.475,47.6,29.124,47.6,32c0,5.684-4.303,9.181-10.4,10.4c1.628,1.43,2.6,3.513,2.6,5.85v8.557 c-0.576,0.181-1.162,0.338-1.755,0.479C49.488,54.56,58,44.277,58,32C58,17.641,46.359,6,32,6z" />
        <path d="M33.813,57.93C33.214,57.972,32.61,58,32,58C32.61,58,33.213,57.971,33.813,57.93z" />
        <path d="M37.786,57.346c-1.164,0.265-2.357,0.451-3.575,0.554C35.429,57.797,36.622,57.61,37.786,57.346z" />
        <path d="M32,58c-0.61,0-1.214-0.028-1.813-0.07C30.787,57.971,31.39,58,32,58z" />
        <path d="M29.788,57.9c-1.217-0.103-2.411-0.289-3.574-0.554C27.378,57.61,28.571,57.797,29.788,57.9z" />
      </svg>
    </>
  );
};

export default GithubLogo;
