import React, { useEffect } from "react";

const Resume = () => {
  useEffect(() => {
    window.location.href =
      "https://drive.google.com/file/d/1JCXXlYvPbPFU38XXVxKSel9uL-2q4sYh/view?usp=sharing";
  }, []);

  return <></>;
};

export default Resume;
